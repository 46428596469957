import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export type Screens = { [key: string]: string };

@Injectable({
	providedIn: 'root'
})
export class MediaWatcherService {
	private _onMediaChange: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

	private screens: Screens = {
		ls: '320px',
		xs: '375px',
		xm: '480px',
		sm: '600px',
		md: '768px',
		lg: '960px',
		xl: '1280px'
	};

	constructor(private _breakpointObserver: BreakpointObserver) {
		this._breakpointObserver
			.observe(Object.values(this.createScreens(this.screens)))
			.subscribe((state) => {
				this.handleBreakpointChange(state);
			});
	}

	// -----------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------

	/**
	 * Getter for _onMediaChange
	 */
	get onMediaChange$(): Observable<string[]> {
		return this._onMediaChange.asObservable();
	}

	// -----------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------

	/**
	 * Creates a screens object with the media query as value
	 * @param screens
	 * @returns Screens object
	 * @private
	 *
	 */
	private createScreens(screens: Screens): Screens {
		const result: Screens = {};

		Object.entries(screens).forEach(([alias, screen]: [string, string]) => {
			result[alias] = `(min-width: ${screen})`;
		});

		return result;
	}

	/**
	 * Handles the breakpoint change
	 * @param state
	 * @private
	 *
	 */
	private handleBreakpointChange(state: BreakpointState): void {
		const screens = this.createScreens(this.screens);
		const matchingAliases: string[] = [];

		Object.entries(state.breakpoints)
			.filter(([, matches]) => matches)
			.forEach(([query]) => {
				const matchingAlias = Object.entries(screens).find(([, q]) => q === query)?.[0];

				if (matchingAlias) {
					matchingAliases.push(matchingAlias);
				}
			});

		this._onMediaChange.next(matchingAliases);
	}
}
